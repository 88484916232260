/* **** Toast **** */
import { Toast } from "bootstrap";

const toastContainer = document.querySelector(".toast");
const toastBody = toastContainer.querySelector(".toast-body");
const toastInit = new Toast(toastContainer, {
  animation: true,
  autohide: true,
  delay: 3000,
});
const setMessage = (message) => {
  toastBody.innerHTML = "";
  toastBody.innerHTML += "<span>" + message + "</span>";
};

/* **** Error **** */
document.addEventListener(
  "wpcf7invalid",
  function (event) {
    const message = event.detail.apiResponse.message;
    toastContainer.classList.remove("success");
    toastContainer.classList.add("error");
    setMessage(message);
    toastInit.show();
  },
  false
);

document.addEventListener(
  "eventDNDCF7",
  function (event) {
    const message = event.detail.message;
    toastContainer.classList.remove("success");
    toastContainer.classList.add("error");
    setMessage(message);
    toastInit.show();
  },
  false
);

/* **** Success **** */
document.addEventListener(
  "wpcf7mailsent",
  function (event) {
    const message = event.detail.apiResponse.message;
    toastContainer.classList.remove("error");
    toastContainer.classList.add("success");
    setMessage(message);
    toastInit.show();
  },
  false
);

/** Burger **/
function toogleBurger() {
  const body = document.querySelector("body");
  body.classList.toggle("navigation-is-open");
}
const burger = document.querySelector("#cd-nav__burger");
burger.addEventListener("click", toogleBurger);
/* Accordion */
// const accordion = () => {
//   if (!document.querySelector(".accordion")) {
//     return;
//   }
//   const accordion = document.querySelector(".accordion");
//   const items = accordion.querySelectorAll(".accordion-item");

//   function toggleItem(item, index) {
//     item.classList.toggle("uk-open");
//     const content = item.querySelector(".uk-accordion-content");
//     if (!content.getAttribute("hidden")) {
//       content.setAttribute("hidden", "true");
//     } else {
//       content.removeAttribute("hidden");
//     }
//     items.forEach((item, key) => {
//       if (index !== key) {
//         item.classList.remove("uk-open");
//         item
//           .querySelector(".uk-accordion-content")
//           .setAttribute("hidden", "true");
//       }
//     });
//   }

//   items.forEach((item, index) => {
//     const title = item.querySelector(".accordion-item__title");
//     title.addEventListener("click", () => toggleItem(item, index));
//   });
// };
// accordion();
